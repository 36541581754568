import React from "react";

const QuoteModalContent = ({ closeModal }) => {
  return (
    <div class="relative w-full max-w-2xl max-h-full bg-[#f0f0f0]  p-6 px-[47px] m-auto h-[816px]">
      <button
        className="text-lg text-black bg-opacity-0 p-5 absolute right-0 top-0 focus-visible:outline-none"
        onClick={closeModal}
      >
        ✕
      </button>

      <iframe
        className="w-full h-full"
        src="https://share.hsforms.com/1Ob4XgOD9SdWeNAJb5srdaw1ghz3"
        title="Quote Modal"
      ></iframe>
    </div>
  );
};

export default QuoteModalContent;
